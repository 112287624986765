import React from 'react';

export function Warning() {
	return (
		<div className={`box box__warnings ${window.screen.width > 1023 ? 'pr-100' : ''}`}>
			<h2 className="mb-15">
				Bem-vindo (a) ao seu painel administrativo da <strong>Wake Commerce.</strong>
			</h2>
			<p className="pb-15">
				A <strong>Wake Commerce</strong> é uma plataforma de digital commerce com a melhor performance garantida do mercado. Por aqui, é
				possível planejar ações para o seu negócio e ter muito mais flexibilidade, escalabilidade e segurança!
			</p>
			<p className="pb-15">
				A plataforma da Wake é pensada para negócios de médio e grande porte que querem ir além na jornada. Por aqui, você irá gerenciar
				pedidos e estoques, conectar canais de venda físicos e digitais, integrar métodos de pagamentos, gerir fretes, entre outras funções.
			</p>
			<p>
				Acesse agora e <strong>faça a diferença na sua busca por resultados.</strong>
			</p>
			<br />
		</div>
	);
}
